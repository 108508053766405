.fa-border {
    border-color: #03D1B2;
}

.grid-end.column {
    display: grid;
    align-content: end;
}

.matching-title {
    font-family: "Helvetica Neue";
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
}

.centered-column {
    display: flex;
    flex-direction: column;
}

.divider {
    width: 100%;
    border-top: 1px solid #03D1B2;
    padding-bottom: 5px;
}

.fullwidth {
    width: 100%;
}