@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #03D1B2;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
}
.xs-loading-spinner {
    width: 20px;
    height: 20px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #03D1B2;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.xs-spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 80px;
}