.table thead th {
    background-color: #03D1B2;
    color: white;
}

.ml--1 {
    margin-left: -1px;
}

.mt--1 {
    margin-top: -1px;
}